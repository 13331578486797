.main_container {
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
}
.reports_title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.dateRange_container {
  display: flex;
  // flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;
}

.dateRange_picker {
  height: 38px !important;
  width: 100%;
}
.ColContainer {
  direction: flex;
  flex-direction: column;
}
.card_container {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.cardStyles {
  width: 70%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  margin-bottom: 15px;
  // padding: 20px !important;
}

.buttons_container {
  display: flex;
  justify-content: space-between;
}
.cancel_button {
  margin-right: 25px;
  font-family: 'SFProText Regular';
  padding-left: 20px;
  padding-right: 20px;
}
.cancel_button:hover {
  background-color: white;
  color: #1c4e9d;
  border-color: #2264ae !important;
}
.download_title {
  font-size: 14px;
  font-family: 'SFProText Regular';
}
.download_icon {
  font-size: 20px;
  margin-right: 10px;
}
.download_icon:hover {
  color: #2264ae;
}

.selectTitle_styles {
  margin-bottom: 5px;
  font-weight: 600;
  font-family: 'SFProText Regular';
  //height: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
