/* @import url('http://fonts.cdnfonts.com/css/cerebri-sans'); */
.container {
  margin-left: 3% !important;
  margin-top: 2% !important;
}
h4 {
  font-size: 18px;
  color: #2364ad;
}
p {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 0px !important;
  font-style: normal;
}
.cardStyles {
  width: 98%;
  top: 20px;
}
h5 {
  font-size: 13px;
}
.badgestyles > sup {
  height: 14px !important;
  font-size: 8px !important;
  background-color: #cbdfff;
  color: #1c4e9d;
  font-weight: 500;
}
.para_styles {
  font-weight: 400;
  font-size: 10px !important;
  line-height: 8px !important;
  font-style: normal;
}
.import_style {
  font-size: 10px !important;
  font-weight: normal !important;
  width: 90px;
}
/* .import_style_success {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #5cb85c;
  color: #f0f0ff;
  padding-top: 6px !important;
  padding-bottom: 21px !important;
  padding-left: 20px !important;
  padding-right: 21px !important;
} */
.import_style_success {
  font-size: 12px !important;
  background-color: #5cb85c;
  color: #f0f0ff;
  font-weight: bold !important;
  height: 36px !important;
  line-height: 18px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
}
.import_style_success:hover {
  font-size: 12px !important;
  background-color: #f0f0ff;
  color: #5cb85c;
  border-color:#5cb85c !important;
  font-weight: bold !important;
  height: 36px !important;
  line-height: 18px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
}
.import_style_success:focus {
  font-size: 12px !important;
  background-color: #5cb85c;
  color: #f0f0ff;
  border-color:#5cb85c !important;
  font-weight: bold !important;
  height: 36px !important;
  line-height: 18px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
}
.import_style_danger {
  font-size: 12px !important;
  color: #f0f0ff;
  background-color: #d9534f;
  font-weight: bold !important;
  height: 36px !important;
  line-height: 18px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
}
.import_style_danger:hover {
  font-size: 12px !important;
  background-color: #f0f0ff;
  color: #d9534f;
  border-color:#d9534f !important;
  font-weight: bold !important;
  height: 36px !important;
  line-height: 18px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
}
.import_style_danger:focus {
  font-size: 12px !important;
  color: #f0f0ff;
  background-color: #d9534f;
  border-color:#d9534f !important;
  font-weight: bold !important;
  height: 36px !important;
  line-height: 18px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
}
.import_style_secondary:hover {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
  padding: 0px 14px !important;
}
.import_style_secondary:active {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
  padding: 0px 14px !important;
}
.import_style_secondary:focus {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
  padding: 0px 14px !important;
}
.searchbar > div > span {
  font-size: 10px !important;
}
.table_container {
  margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 11px;
  //
}
.table_container td {
  font-size: 11px;
}
.formstyles {
  margin-top: 2% !important;
  width: 98%;
}
.formstyles label {
  font-size: 12px !important;
}
.select_styles > div > div > div > div > div {
  height: 33px !important;
  font-size: 12px;
}
.select_styles > div > div > div > div > div > span {
  line-height: 31px !important;
}
.date_styles > div > div > div > div {
  height: 33px !important;
  width: 100% !important;
}
.date_styles > div > div > div > div > div > input {
  font-size: 12px !important;
}
.input_styles > div > div > div > span {
  height: 33px !important;
  font-size: 12px !important;
  width: 100% !important;
}
.input_styles > div > div > div > span > input {
  font-size: 12px !important;
}
.textarea_styles > div > div > div > span > textarea {
  font-size: 12px !important;
}
.divider_style {
  margin-bottom: 17px;
  margin-top: 2px;
}
.import_style_secondary {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #1c4e9d;
  color: #f0f0ff;

  padding-left: 18px;
  padding-right: 18px;
  padding-top: 4px;
  padding-bottom: 5px;
}
.select_styles > div {
  margin-top: -11px;
  font-size: 13px;
}
.select_styles_addr > div > div > div {
  margin-top: 3px;
}
.select_styles{
  margin-bottom: 0 !important;
}
.addr_typo {
  margin-top: 9px;
}
/**************Add New Style***********/
.page_title1 {
  color: #2364ad;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
}
.page_sub_title {
  color: #667085;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: -2px;
}
.customButton_import_style {
  font-size: 12px !important;
  width: 90px;
  height: 30px !important;
  padding: 0px 19px !important;
  padding-left: 14px !important;
}
.itemsDetails{
  font-weight: bold;
  font-size: 14px;
}