.select_styles > div > label {
  height: 33px !important;
  font-size: 12px !important;
}
.select_styles > div > div > div > span > textarea {
  font-size: 12px;
}
.import_style_approve {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #28a745;
  color: #f0f0ff;
  // font-family: 'Cerebri Sans';
  padding-bottom: 21px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 6px !important;
}
.import_style_reject:hover {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}
.import_style_approve:active {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}
.import_style_approve:focus {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}

.import_style_cancel {
  font-size: 10px !important;
  font-weight: 600 !important;
  // font-family: 'Cerebri Sans';
  padding-bottom: 21px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 6px !important;
}
/* ---------------new Style---------------- */
.import_style_IssuerModal {
  font-size: 13px !important;
  font-family: 'SFProText Bold';
  height: 36px !important;
  line-height: 1.1 !important;
  // background-color: #2364ad;
  // color: #f0f0ff;
  padding: 0px 20px;
}
.import_style_IssuerModal:hover {
  //background-color: #2364ad !important;
  color: #2364ad !important;
  border-color: #2364ad !important ;
}
.import_style_IssuerModal:active {
  color: #2364ad !important;
  border-color: #2364ad !important ;
}
.import_style_IssuerModal:focus {
  color: #2364ad !important;
  border-color: #2364ad !important ;
}
.import_style_cancel_IssuerModal {
  font-size: 14px !important;
  font-family: 'SFProText Bold';
  padding: 0px 20px;
  height: 36px !important;
  line-height: 1.1 !important;
}
.import_style_cancel_IssuerModal:hover {
  color: #2364ad !important;
  border-color: #2364ad !important ;
}

.import_style_reject_IssuerModal:hover {
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}
.check_circle_icon > div > div > div > span {
  font-size: 16px;
  color: #2364ad !important;
  font-weight: 600;
}
.input_styles label {
  font-size: 12px !important;
}
.input_styles > div > div > div > span {
  height: 33px !important;
  font-size: 12px !important;
  width: 100% !important;
  font-family: 'SFProText Regular';
}
.input_styles > div > div > div > span > input {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
.date_styles label {
  font-size: 12px !important;
}
.date_styles > div > div > div > div {
  height: 33px !important;
  width: 100% !important;
}
.date_styles > div > div > div > div > div > input {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
