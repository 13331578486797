.main_container {
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
}
.reports_title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.card_container {
  margin-top: 3%;
  margin-left: 1%;
  margin-right: 4%;
}
.cardStyles {
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  //padding: 0px !important;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  margin-bottom: 15px;
  padding: 20px !important;
}
.cardStyles > div {
  padding: 30px !important;
  width: 100%;
}
.dateRange_container {
  display: flex;
  // flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;
}
.dateRange_RowContainer {
  //margin-top: 15px;
}
.dateRange_picker {
  height: 38px !important;
  // padding: 0px 9px 6px;
  width: 100%;
}
.ColContainer {
  direction: flex;
  flex-direction: column;
}
.buttons_container {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel_button {
  margin-right: 25px;
  font-family: 'SFProText Regular';
}
.submit_button {
  color: #ffffff;
  background-color: #2264ae;
  font-family: 'SFProText Regular';
}
.selectTitle_styles {
  margin-bottom: 5px;
  font-weight: 600;
  font-family: 'SFProText Regular';
  //height: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
//---------------------------------

// .row_container_setting {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   background-color: white;
//   padding-top: 20px;
//   padding-bottom: 15px;
//   padding-left: 100px;
//   padding-right: 100px;
// }
// .container {
//   padding: 25px 100px;
// }
// .row_container {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// }

// .myGift_title {
//   font-weight: 700;
//   font-size: 25px;
//   line-height: 38px;
//   color: #2264ae;
//   // background-color: white;
//   // padding: 100px 50 px 50px;
// }
// .myGift_text {
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 24px;
//   color: #667085;
// }
// .cardContainer {
//   margin-top: 50px;
//   margin-left: 150px;
//   margin-right: 150px;
// }
// .cardStyles {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   //padding: 0px !important;
//   background: #ffffff;
//   border: 1px solid #eaecf0;
//   box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
//     0px 4px 6px -2px rgba(16, 24, 40, 0.03);
//   border-radius: 10px;
//   margin-bottom: 20px;
// }
// .cardStyles > div {
//   padding: 16px 24px 16px 24px !important;
//   width: 100%;
// }
// .card_content {
//   font-weight: 600;
//   font-size: 16px;
// }
// .icon_size {
//   font-size: 30px;
// }
// .card_content_text {
//   font-size: 16px;
//   margin-left: 10px;
//   margin-top: 10px;
// }
// .gift_icon_size {
//   margin-top: 20px;
// }
// .gift_container {
//   display: flex;
//   justify-content: space-between;
// }
// .back_btn {
//   float: right;
//   display: flex;
//   justify-content: flex-end;
//   font-weight: 600;
//   margin-top: 10px;
// }
