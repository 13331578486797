.not_allowed_icon >div >div >div>span{
       
background: #FEE4E2;
width: 40px;
height: 40px;
padding-top: 4px;
border: 8px solid #FEF3F2;
border-radius: 28px;
color: #D92D20;
} 
.select_styles >div>label{
    height: 33px !important;
    font-size: 12px;
}
.select_styles >div>div>div>span>textarea{
    font-size: 12px;
}
.import_style_reject{
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #D92D20;
    color: #F0F0FF;
    font-family: 'Cerebri Sans';
    padding-bottom: 21px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 6px !important;
}
.import_style_reject:hover {
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #D92D20 !important;
    color: #F0F0FF;
}
.import_style_reject:active {
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #D92D20 !important;
    color: #F0F0FF;
}
.import_style_reject:focus {
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #D92D20 !important;
    color: #F0F0FF;
}

.import_style_cancel {
    font-size: 10px !important;
    font-weight:normal !important;
    font-family: 'Cerebri Sans';
    padding-bottom: 21px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    padding-top: 6px !important;
}