.select_styles > div > label {
  height: 33px !important;
  font-size: 12px;
}
.select_styles > div > div > div > span > textarea {
  font-size: 12px;
}
.import_style_approve {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #2364ad;
  color: white;
  padding-bottom: 21px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 6px !important;
}
.import_style_approve:hover {
  background-color: white !important;
  color: #2364ad;
}
.import_style_reject:hover {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}
.import_style_approve:active {
  background-color: #2364ad !important;
  color: white;
}
.import_style_approve:focus {
  background-color: #2364ad !important;
  color: white;
}

.import_style_cancel {
  font-size: 10px !important;
  font-weight: 600 !important;
  padding-bottom: 21px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 6px !important;
}
.check_circle_icon > div > div > div > span {
  font-size: 16px;
  color: #2364ad !important;
  font-weight: 600;
}
.import_style_success_ho {
  font-size: 12px !important;
  background-color: #2364ad;
  color: white;
  font-weight: bold !important;
  height: 36px !important;
  line-height: 18px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
}
.import_style_success_ho:hover {
  background-color: white;
  color: #2364ad;
  border-color: #2364ad !important;
}
/* .import_style_success:focus {
  background-color: #2364ad;
  color: white;
  border-color: #2364ad !important;
} */

.import_style_success_cancel {
  background-color: white;
  border-color: #dcdce4 !important;
  //box-shadow: 0 2px 0 rgba(0,0,0,.015);
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px !important;
  font-weight: bold !important;
  height: 36px !important;
  line-height: 18px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
}
.import_style_success_cancel:hover {
  color: #2364ad;
  border-color: #2364ad !important;
}
.import_style_success_cancel:active{
  color: #2364ad;
  border-color: #2364ad !important;
}
