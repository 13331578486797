/* @import url('http://fonts.cdnfonts.com/css/cerebri-sans'); */
/* .container {
  margin-left: 3% !important;
  margin-top: 2% !important;
}
h4 {
  font-size: 20px;
  color: #2364ad;
  font-family: 'Cerebri Sans';
}
p {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 0px !important;
  font-style: normal;
  font-family: 'Cerebri Sans';
}
.cardStyles {
  width: 98%;
  top: 20px;
}
h5 {
  font-size: 15px;
}
.badgestyles > sup {
  height: 14px !important;
  font-size: 10px !important;
  background-color: #cbdfff;
  color: #1c4e9d;
  font-weight: 500;
  font-family: 'Cerebri Sans';
}
.para_styles {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 8px !important;
  font-style: normal;
  font-family: 'Cerebri Sans';
}
.import_style {
  font-size: 12px !important;
  width: 90px;
  font-family: 'Cerebri Sans';
  padding: 0px 19px !important;
}

.import_style_secondary {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #1c4e9d;
  color: #f0f0ff;
  font-family: 'Cerebri Sans';
}
.import_style_secondary:hover {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:active {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:focus {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.searchbar > div > span {
  font-size: 12px !important;
}
.table_container {
  margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 13px;
  // font-family: 'Cerebri Sans';
}
.table_container td {
  font-size: 13px;
  font-family: 'Cerebri Sans';
}
.badgeStyles {
  background-color: #ecfdf3;
  padding: 0px 9px 2px 9px;
  border-radius: 12px;
}
.badgeStyles > span {
  color: #027a48;
  font-size: 13px;
}
.badgeReqStyles {
  background-color: #eff8ff;
  padding: 0px 9px 2px 9px;
  border-radius: 12px;
}
.badgeReqStyles > span {
  color: #175cd3;
  font-size: 13px;
}
.badgeRejStyles {
  background-color: #ffdada;
  padding: 0px 9px 2px 9px;
  border-radius: 12px;
}
.badgeRejStyles > span {
  color: #d92d20;
  font-size: 13px;
}
.badgePanStyles {
  background-color: #ffdada;
  padding: 0px 9px 2px 9px;
  border-radius: 12px;
}
.badgePenStyles > span {
  color: #d92d20;
  font-size: 13px;
}
.searchbar > div > span {
  height: 28px;
}
.searchbar > div > span > input {
  font-size: 13px;
  margin-left: 12px;
}
.badgeGpGenStyles {
  background-color: #fdf2fa;
  padding: 0px 9px 2px 9px;
  border-radius: 12px;
}
.badgeGpGenStyles > span {
  color: #c11574;
  font-size: 13px;
}
.badgeGpPriStyles {
  background-color: #f2f4f7;
  padding: 0px 9px 2px 9px;
  border-radius: 12px;
}
.badgeGpPriStyles > span {
  color: #344054;
  font-size: 13px;
}
 */
/* @import url('http://fonts.cdnfonts.com/css/cerebri-sans'); */
.container {
  margin-left: 3% !important;
  margin-top: 2% !important;
}
h4 {
  font-size: 20px;
  color: #2364ad;
}
p {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 0px !important;
  font-style: normal;
}
.cardStyles_approver {
  width: 98%;
  top: 10px;
}
h5 {
  font-size: 15px;
}
.badgestyles > sup {
  height: 14px !important;
  font-size: 12px !important;
  background-color: #cbdfff;
  color: #1c4e9d;
  font-weight: 500;

  padding-top: 2px;
  padding-bottom: 15px;
}
.para_styles {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 8px !important;
  font-style: normal;
}
.import_style {
  font-size: 12px !important;
  width: 90px;
  padding: 0px 19px !important;
}

.import_style_secondary {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #1c4e9d;
  color: #f0f0ff;
}
.import_style_secondary:hover {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:active {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:focus {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.searchbar > div > span {
  font-size: 12px !important;
}
.table_container {
  margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 13px;
  //
}
.table_container td {
  font-size: 13px;
}
.badgeStyles {
  background-color: #ecfdf3;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgeStyles > span {
  color: #027a48;
  font-size: 12px;
}
.badgeReqStyles {
  background-color: #eff8ff;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgeReqStyles > span {
  color: #175cd3;
  font-size: 12px;
}
.badgePendStyles {
  background-color: #beebf7;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgePendStyles > span {
  color: #046f89;
  font-size: 12px;
}
.badgeRejStyles {
  background-color: #ffdada;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgeRejStyles > span {
  color: #d92d20;
  font-size: 12px;
}
.badgePanStyles {
  background-color: #ffdada;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgePenStyles > span {
  color: #d92d20;
  font-size: 12px;
}
.badgeCanStyles {
  background-color: #f5cccf;
  padding: 3px 9px 4px 7px;
  border-radius: 12px;
}
.badgeCanStyles > span {
  color: #820b03;
  font-size: 12px;
}
.searchbar > div > span {
  height: 34px;
}
.searchbar > div > span > input {
  font-size: 13px;
  margin-left: 12px;
}
.dateRange {
  height: 30px;
}
.badgeGpGenStyles {
  background-color: rgb(237, 247, 224);
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgeGpGenStyles > span {
  color: #059720;
  font-size: 12px;
}
.badgePartRecivStyles {
  background-color: #fdf2fa;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgePartRecivStyles > span {
  color: #c11574;
  font-size: 12px;
  text-transform: capitalize;
}
.badgeGpPriStyles {
  background-color: #f2f4f7;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgeGpPriStyles > span {
  color: #344054;
  font-size: 12px;
}
.badgeCloseStyles {
  background-color: #dae6f8;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgeCloseStyles > span {
  font-size: 12px;
}

.badgeRequestedStyles {
  background-color: #d3f0f8;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
}
.badgeRequestedStyles > span {
  color: #046f89;
  font-size: 12px;
}
/**************Add New Style***********/
.page_title {
  color: #2364ad;

  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
}
.page_sub_title {
  color: #667085;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: -2px;
}
.Gate_pass_req_title {
  color: rgba(0, 0, 0, 0.7);
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
}
.Gate_pass_req_sub_title {
  color: #667085;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 2px;
}

.button_import_style {
  label {
    font-size: 13px !important;
    font-weight: 600 !important;
    height: 34px !important;
    padding: 0px 20px !important; //Tab padding
    line-height: 32px !important;
    background-color: #1c4e9d;
    color: #f0f0ff;
  }
  label:hover {
    // font-size: 13px !important;
    // font-weight: 600 !important;
    // height: 31px !important;
    // padding: 0px 15px !important; //Tab padding
    // line-height: 27px !important;
    background-color: #f0f0ff;
    color: #1c4e9d;
  }
}
.button_import_style_issuer {
  label {
    font-size: 12px !important;
    font-weight: 600 !important;
    height: 34px !important;
    padding: 0px 15px !important; //Tab padding
    line-height: 32px !important;
    text-align: center;
    background-color: #1c4e9d;
    color: #f0f0ff;
  }
  label:hover {
    background-color: #f0f0ff;
    color: #1c4e9d;
  }
}
.customButton_import_style {
  font-size: 12px !important;
  width: 90px;
  height: 30px !important;
  padding: 0px 19px !important;
  padding-left: 14px !important;
}
.customButton_style_secondary {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #1c4e9d;
  color: #f0f0ff;
  height: 30px !important;
}
.customButton_style_secondary:hover {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #f0f0ff;
  color: #1c4e9d;
  height: 30px !important;
}
/* .delete_icon {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  line-height: 0px !important;
  height: 23px !important;
  border: 1px solid transparent !important;
}
.delete_icon:hover {
  border: 1px solid #d92d20 !important;
} */
.delete_icon > svg {
  height: 11.5px !important;
  width: 11.5px !important;
  color: #979ead !important;
}
.delete_icon > svg:hover {
  // border: 1px solid #d92d20 !important;
  color: #d92d20 !important;
  background-color: #fcceca;
  border-radius: 20% !important;
}
/* .edit_icon {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  line-height: 0px !important;
  height: 23px !important;
  border: 1px solid transparent !important;
}
.edit_icon:hover {
  border: 1px solid #1cc637 !important;
} */
.edit_icon {
  font-size: 14px;
  color: #979ead !important;
}
.edit_icon:hover {
  color: #03b120 !important;
  background-color: #dcf7e1;
  border-radius: 20% !important;
}
