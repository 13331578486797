/* @import url('http://fonts.cdnfonts.com/css/cerebri-sans'); */
.container {
  margin-left: 3% !important;
  margin-top: 2% !important;
}
h4 {
  font-size: 20px;
  color: #2364ad;
  font-family: 'Cerebri Sans';
}
p {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 0px !important;
  font-style: normal;
  font-family: 'Cerebri Sans';
}
.cardStyles {
  width: 98%;
  top: 20px;
}
h5 {
  font-size: 15px;
}
.badgestyles > sup {
  height: 14px !important;
  font-size: 12px !important;
  background-color: #cbdfff;
  color: #1c4e9d;
  font-weight: 500;
  padding-top: 2px;
  padding-bottom: 15px;
}

.para_styles {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 8px !important;
  font-style: normal;
  font-family: 'Cerebri Sans';
}
.import_style {
  font-size: 12px !important;
  width: 90px;
  font-family: 'Cerebri Sans';
  padding: 0px 19px !important;
}

.import_style_secondary {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #1c4e9d;
  color: #f0f0ff;
  font-family: 'Cerebri Sans';
}
.import_style_secondary:hover {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:active {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:focus {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.searchbar > div > span {
  font-size: 12px !important;
}
.table_container {
  margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 13px;
  // font-family: 'Cerebri Sans';
}
.table_container td {
  font-size: 13px;
  font-family: 'Cerebri Sans';
}
/**************Add New Style***********/
.page_title {
  color: #2364ad;
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
}
.page_sub_title {
  color: #667085;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: -2px;
}
.customButton_style_secondary {
  font-size: 12.5px !important;
  font-weight: 600 !important;
  background-color: #1c4e9d;
  color: white;
  height: 32px !important;
  top:20px;
  margin-right: 25px;
}
.customButton_style_secondary:hover {
  background-color: white;
  color: #1c4e9d;
}
