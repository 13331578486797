.container {
  height: 100vh;
  .left_outer_container {
    background: white;
    height: 100%;
    padding: 60px 50px;
    .left_text {
      position: absolute;
      left: 3.47%;
      right: 70.69%;
      top: 6.67%;
      bottom: 87.73%;
      font-weight: 700;
      font-size: 42px;
      line-height: 42px;
      letter-spacing: -0.02em;
      color: #1e206a;
      width: 60%;
    }
    .tata_logo_div {
      margin-left: 0px !important;
      margin-top: 0px !important;
      .tata_logo {
        height: 40px;
      }
    }
    .vehicle_image {
      height: 350px;
      margin-top: 13%;
      margin-left: 72px;
    }

    .left_text_2 {
      position: absolute;
      left: 3.82%;
      right: 48.96%;
      top: 13.6%;
      bottom: 79.2%;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.02em;
      color: #1e206a;
      width: 60%;
    }
    .left_image {
      position: absolute;
      width: 680px;
      height: 499.26px;
      left: 230px;
      top: 250px;
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    // height: 100%;
    position: relative;
    width: auto !important;
    max-width: 100%;
    height: auto !important;
    overflow-y: hidden;
    overflow-x: hidden;
    .left_outer_container {
      position: relative;
      background: white;
      height: auto !important;
      padding: 60px 50px;
      .left_text {
        position: absolute;
        left: 3.47%;
        right: 70.69%;
        top: 6.67%;
        bottom: 87.73%;
        font-weight: 700;
        font-size: 42px;
        line-height: 42px;
        letter-spacing: -0.02em;
        color: #1e206a;
        width: 60%;
      }

      .tata_logo_div {
        margin-left: -10px !important;
        margin-top: -10px !important;
        .tata_logo {
          height: 30px;
        }
      }
      .vehicle_image {
        position: absolute;
        width: 160% !important;
        margin-top: 64%;
        margin-left: -20px !important;
      }

      .welcome_col {
        margin-left: -30% !important;
      }

      .left_text_2 {
        position: absolute;
        left: 3.82%;
        right: 48.96%;
        top: 13.6%;
        bottom: 79.2%;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: -0.02em;
        color: #1e206a;
        width: 60%;
      }
      .left_image {
        position: absolute;
        width: 680px;
        height: 499.26px;
        left: 230px;
        top: 250px;
      }
    }
  }
}
/* @media only screen and (max-width: 667px) and (min-width: 375px) {
  .container {
    // height: 100%;
    position: relative;
    width: auto !important;
    max-width: 100%;
    height: auto !important;
    overflow-y: hidden;
    overflow-x: hidden;
    .left_outer_container {
      position: relative;
      background: white;
      height: 100%;
      padding: 60px 50px;
      .left_text {
        position: absolute;
        left: 3.47%;
        right: 70.69%;
        top: 6.67%;
        bottom: 87.73%;
        font-weight: 700;
        font-size: 42px;
        line-height: 42px;
        letter-spacing: -0.02em;
        color: #1e206a;
        width: 60%;
      }

      .tata_logo_div {
        margin-left: -20px !important;
        margin-top: -10px !important;
        .tata_logo {
          height: 30px;
        }
      }
      .vehicle_image {
        position: absolute;
        width: 160% !important;
        margin-top: 50%;
        margin-left: -20px !important;
      }

      .welcome_col {
        margin-left: -30% !important;
      }

      .left_text_2 {
        position: absolute;
        left: 3.82%;
        right: 48.96%;
        top: 13.6%;
        bottom: 79.2%;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: -0.02em;
        color: #1e206a;
        width: 60%;
      }
      .left_image {
        position: absolute;
        width: 680px;
        height: 499.26px;
        left: 230px;
        top: 250px;
      }
    }
  }
} */
