.App {
  font-family: sans-serif;
  text-align: center;
}

.watermarked {
  position: relative;
}

.watermarked::before {
  position: absolute;
  margin-top: 15%;
  margin-left: 20%;
  display: block;
  transform: rotate(-45deg);
  content: attr(data-watermark);
  opacity: 0.4;
  line-height: 3em;
  letter-spacing: 2px;
  color: #9c9898;
  font-size: 10rem;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
}
