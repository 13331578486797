.select_styles > div > label {
  height: 33px !important;
  font-size: 12px;
}
.select_styles > div > div > div > span > textarea {
  font-size: 12px;
}
.approve_modal_button {
  font-size: 12px !important;
  font-weight: 600 !important;
  height: 34px;
  line-height: 1.1;
  padding: 0px 15px;
}
.approve_modal_button:hover {
  background-color: white !important;
  color: #2364ad;
  border-color: #2364ad !important ;
}
.import_style_cancel {
  font-size: 12px !important;
  font-weight: 600 !important;
  height: 34px;
  line-height: 1.1;
  padding: 0px 15px;
}
.import_style_cancel:hover {
  background-color: white !important;
  color: #2364ad;
  border-color: #2364ad !important;
}
.import_style_reject:hover {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}
.import_style_approve:active {
  background-color: #2364ad !important;
  color: white;
}
.import_style_approve:focus {
  background-color: #2364ad !important;
  color: white;
}

.check_circle_icon > div > div > div > span {
  font-size: 16px;
  color: #2364ad !important;
  font-weight: 600;
}
