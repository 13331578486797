/* .check_circle_icon >div >div >div>span{
       
background: #d3e8d8;
width: 40px;
height: 40px;
padding-top: 4px;
border: 8px solid #d3e8d8;
border-radius: 28px;
color: #28a745!important;
} 
.select_styles >div>label{
    height: 33px !important;
    font-size: 12px;
}
.select_styles >div>div>div>span>textarea{
    font-size: 12px;
}
.import_style_approve{
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #28a745;
    color: #F0F0FF;
    font-family: 'Cerebri Sans';
    padding-bottom: 21px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 6px !important;
}
.import_style_reject:hover {
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #28a745 !important;
    color: #F0F0FF;
}
.import_style_approve:active {
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #28a745 !important;
    color: #F0F0FF;
}
.import_style_approve:focus {
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #28a745 !important;
    color: #F0F0FF;
}

.import_style_cancel {
    font-size: 10px !important;
    font-weight:normal !important;
    font-family: 'Cerebri Sans';
    padding-bottom: 21px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    padding-top: 6px !important;
} */

//----------------------------------------------------------------------------------------------------
.input_styles label {
  font-size: 12px !important;
}
.select_styles label {
  font-size: 12px !important;
}
.date_styles label {
  font-size: 12px !important;
}
.textarea_styles label {
  font-size: 12px !important;
}
.select_styles > div > div > div > div > div {
  height: 33px !important;
  font-size: 12px;
  font-family: 'SFProText Regular';
}
.select_styles > div > div > div > div > div > span {
  line-height: 31px !important;
  font-family: 'SFProText Regular';
}
.date_styles > div > div > div > div {
  height: 33px !important;
  width: 100% !important;
  font-family: 'SFProText Regular';
}
.date_styles > div > div > div > div > div > input {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
.input_styles > div > div > div > span {
  height: 33px !important;
  font-size: 12px !important;
  width: 100% !important;
  font-family: 'SFProText Regular';
}
.input_styles > div > div > div > span > input {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
.textarea_styles > div > div > div > span > textarea {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
.divider_style {
  margin-bottom: 17px;
  margin-top: 2px;
}

.return_button {
  padding: 6px 18px !important;
  font-size: 12px !important;
  height: 37px !important;
  font-weight: 500;
  background-color: #2364ad;
  color: white;
  margin-left: 10px;
}
.cancel_button {
  padding: 6px 18px !important;
  font-size: 12px !important;
  height: 37px !important;
  font-weight: 500;

  margin-left: 10px;
}
.grey_holder {
  background: #f5f7f9;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px !important;
}
.custom_file_upload2 {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 18px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 12px !important;
  font-weight: 500;
  height: 37px !important;
}
.custom_file_upload2:hover {
  background-color: #2364ad;
  color: white;
  transition: all 0.4s ease-in-out;
}
.uploaded_files {
  float: right;
}
.item_history_button {
  margin-right: 8px;
  line-height: 1;
  height: 32px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
.item_history_button:hover {
  background-color: #1c4e9d;
  color: white;
}
/* ------globally set modal body padding--------------- */
:global(.ant-modal-body) {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
