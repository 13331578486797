.check_circle_icon > div > div > div > span {
  background: #d3e8d8;
  width: 40px;
  height: 40px;
  padding-top: 4px;
  border: 8px solid #d3e8d8;
  border-radius: 28px;
  color: #28a745 !important;
}
.select_styles > div > label {
  height: 33px !important;
  font-size: 12px;
}
.select_styles > div > div > div > span > textarea {
  font-size: 12px;
  font-family: 'SFProText Regular';
}
.import_style_approve {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #28a745;
  color: #f0f0ff;
  // font-family: 'Cerebri Sans';
  padding-bottom: 21px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 6px !important;
}
.import_style_reject:hover {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}
.import_style_approve:active {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}
.import_style_approve:focus {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}

.import_style_cancel {
  font-size: 10px !important;
  font-weight: normal !important;
  //font-family: 'Cerebri Sans';
  padding-bottom: 21px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 6px !important;
}
//--------------------Item History---------------------------
.title_itemHistory {
  margin-right: 4px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'SFProText Regular';
}
:global(.ant-timeline-item) {
  font-size: 13px;
}
.ok_button_style {
  background-color: #1c4e9d;
  color: white;
}
.ok_button_style:hover {
  background-color: white;
  color: #1c4e9d;
}
.doc_uploaded {
  color: #1c4e9d;
}
.doc_uploaded:hover {
  color: #4945ff;
}
/* :global(.ant-timeline-item-last > .ant-timeline-item-tail) {
  display: block !important;
} */
/* :global(.ant-timeline-item-tail) {
  display: block !important;
}
 */
