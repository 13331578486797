/* @import url('http://fonts.cdnfonts.com/css/cerebri-sans'); */
.right_outer_container {
  height: 100%;
  width: 60%;
  margin: auto;
  .right_image {
    width: 50%;
    margin: 0 auto;
    font-size: 20px;
    width: 360px;
    height: 38px;

    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #101828;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-left: -5px !important;
  }
  .right_image > p {
    font-style: normal;
    font-size: 10px;
    margin-top: -28px !important;
  }
}
.form_width {
  width: 100% !important;
}
.left_outer_container {
  background: white;
  height: 100%;
  padding: 60px 50px;
}
.secondary_button {
  background: white !important;
  color: #222222 !important;
  border-radius: 4px;
  border: 1px solid #2364ad !important ;
}
.secondary_button:hover {
  color: white !important;
  background: #222222 !important;
}
.secondary_forgot_password {
  color: #222222 !important;
}
.right_outer_container {
  width: 100% !important;
  // position: absolute;
  // left: -15.56%;
  // right: -10.56%;
  // top: -20%;
  // bottom: -10%;
  // Width:1080px;
  height: 100%;
  // Top:-90px;
  // Left:656px;
}

.content {
  position: absolute;
  justify-content: center;
  display: block;
  align-items: center;
  margin-top: 30%;
  margin-left: 27%;
}
.right_ellipse {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: -999;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  font-weight: 700;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
}
.sub_title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */

  /* Dark text */

  color: white;
}

.rows_checkox {
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: white;

  img {
    width: 25px;
    height: 25.26px;
  }
}

:global(.ant-col-12) {
  max-height: 100vh;
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .secondary_button {
    color: white !important;
    background: #2364ad !important;
    border-radius: 6px;
    border: 1px solid #2364ad;
    margin-top: 130% !important;
    margin-left: -44% !important;
    font-size: 18px !important;
    height: 48px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .secondary_button:hover {
    background: white !important;
    color: #2364ad !important;
  }

  .content {
    position: absolute;
    justify-content: center;
    display: block;
    align-items: start;
    margin-top: 35%;
    margin-left: 0 !important;
  }
  .right_ellipse {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: -999;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: none;
    }
  }

  .title {
    width: 310px !important;
    font-weight: 700;
    font-size: 24px !important;
    color: #2364ad !important;
    margin-top: 28% !important;
    margin-left: -54% !important;
  }
  .sub_title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: white;
    display: none;
  }

  .rows_checkox {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: none;
    color: white;

    img {
      width: 25px;
      height: 25.26px;
      display: none;
    }
  }

  :global(.ant-col-12) {
    max-height: 100vh;
  }
}
@media only screen and (max-width: 844px) and (min-width: 390px) {
  .secondary_button {
    position: absolute;
    color: white !important;
    background: #2364ad !important;
    border-radius: 6px;
    border: 1px solid #2364ad;
    margin-top: 145% !important;
    margin-left: -43% !important;
    font-size: 18px !important;
    height: 48px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .secondary_button:hover {
    background: white !important;
    color: #2364ad !important;
  }

  .title {
    width: 310px !important;
    font-weight: 700;
    font-size: 24px !important;
    color: #2364ad !important;
    margin-top: 28% !important;
    margin-left: -54% !important;
  }
  .right_ellipse {
    // width: 100vw;
    // height: 100vh;
    position: relative;
    z-index: -999;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: none;
    }
  }
  .sub_title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: white;
    display: none;
  }

  .rows_checkox {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: none;
    color: white;

    img {
      width: 25px;
      height: 25.26px;
      display: none;
    }
  }
}
/* @media only screen and (max-width: 667px) and (min-width: 375px) {
  .secondary_button {
    color: white !important;
    background: #2364ad !important;
    border-radius: 4px;
    border: 1px solid #2364ad;
    margin-top: 155% !important;
    margin-left: -50% !important;
    font-size: 20px !important;
    height: 64px !important;
  }
  .secondary_button:hover {
    background: white !important;
    color: #2364ad !important;
  }
  .secondary_forgot_password {
    color: #222222 !important;
  }
  .title {
    width: 250px !important;
    font-weight: 700;
    font-size: 30px !important;
    //color: rgba(255, 255, 255, 1);
    color: #2364ad !important;
    margin-top: 30% !important;
    margin-left: -44% !important;
  }
  .right_ellipse {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: -999;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: none;
    }
  }
  .sub_title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: white;
    display: none;
  }

  .rows {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: none;
    color: white;

    img {
      width: 25px;
      height: 25.26px;
      display: none;
    }
  }
} */
