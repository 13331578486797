.check_circle_icon > div > div > div > span {
  background: #d3e8d8;
  width: 40px;
  height: 40px;
  padding-top: 4px;
  border: 8px solid #d3e8d8;
  border-radius: 28px;
  color: #28a745 !important;
}
.select_styles > div > label {
  height: 33px !important;
  font-size: 12px;
}
.select_styles > div > div > div > span > textarea {
  font-size: 12px;
}
.import_style_approve {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #28a745;
  color: #f0f0ff;

  padding-bottom: 21px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 6px !important;
}
.import_style_reject:hover {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}
.import_style_approve:active {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}
.import_style_approve:focus {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}

.import_style_cancel {
  font-size: 10px !important;
  font-weight: 600 !important;

  padding-bottom: 21px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 6px !important;
}
/* ---------------new Style---------------- */
.import_style_IssuerModal {
  font-size: 14px !important;
  font-family: 'SFProText Bold';
  background-color: #2364ad;
  height: 40px !important;
  line-height: 1.1 !important;
  color: #f0f0ff;
  padding: 0px 20px;
}
.import_style_IssuerModal:hover {
  background-color: white !important;
  color: #2364ad !important;
  border-color: #2364ad !important;
}
.import_style_IssuerModal:active {
  // background-color: #2364ad !important;
  // color: #f0f0ff;
  background-color: white !important;
  color: #2364ad !important;
  border-color: #2364ad !important;
}
.import_style_IssuerModal:focus {
  // background-color: #2364ad !important;
  // color: #f0f0ff;
  background-color: white !important;
  color: #2364ad !important;
  border-color: #2364ad !important;
}
.import_style_cancel_IssuerModal {
  font-size: 14px !important;
  font-family: 'SFProText Bold';
  height: 40px !important;
  line-height: 1.1 !important;
  padding: 0px 20px;
}
.import_style_cancel_IssuerModal:hover {
  color: #2364ad !important;
  border-color: #2364ad !important;
}
.import_style_reject_IssuerModal:hover {
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #28a745 !important;
  color: #f0f0ff;
}

/* ////////--------------------new Style------------------------------------ */
.container {
  margin-left: 3% !important;
  margin-top: 2% !important;
  margin-right: 2%;
  font-family: 'SFProText Regular';
}

.image_viewer_card {
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  border-radius: 5px 5px 0px 0px;

  :global(.ant-card-body) {
    padding: 20px;
    background-color: #eaeaef;
    padding-left: 60px;
    padding-right: 60px;
  }
}
.page_header h2 {
  font-size: 30px;
  font-weight: bold;
  color: #486aae;
}

.header_sub {
  color: #486aae;
  font-size: 16px !important;
  padding-top: 8px;
  font-family: 'SFProText Regular';
}
.tatatable_margin {
  margin-top: 20px;
}
.tatatable {
  width: 100%;
}
.tatatable_header {
  border: 1px solid #000;
  text-align: left;
  padding: 3px 5px;
}
.tatatable_row {
  border: 1px solid #000;
  text-align: left;
}
.tatatable_td {
  border: 1px solid #000;
  text-align: left;
  padding: 3px 5px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.tatatable th {
  background-color: #bdd6ee !important;
  font-size: 14px;
  font-weight: 800 !important;
}
.tataText_row .tataText_div {
  text-align: left !important;
  margin-top: 10px;
}
.tatatable_dis_width {
  width: 50% !important;
}
