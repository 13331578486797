.custom_layout_container {
  min-height: 100vh;

  .sider_container {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #2364ad;
    box-shadow: 0px 0px 5px rgba(39, 49, 58, 0.1);

    :global(.ant-layout-sider-children) {
      :global(.ant-menu.ant-menu-inline-collapsed) {
        margin: 0 8px; //selected tab whight background
        background: #2364ad;
      }
      :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)) {
        :global(.ant-menu-item) {
          margin: 0;
          background: #2364ad;
          border-radius: 4px;
          margin-bottom: 20px !important; //second icon in Issuer role
          :global(.anticon) {
            color: white;
          }
        }
        :global(.ant-menu-item-selected) {
          background: #ffffff;
          :global(.anticon) {
            color: #2364ad;
          }
        }
      }
    }
  }
  .sider_logo {
    height: 37px; //32
    margin: 16px;
  }

  .site_layout {
    margin-left: 64px;
    background: #2364ad;
    .site_layout_header {
      position: fixed;
      z-index: 1;
      width: calc(100% - 64px);
      background: #2364ad;
      padding: 0 10px 0 20px;
      height: 20px;
    }
    .site_layout_content {
      // margin: 20px;
      margin-top: 20px;
      border-radius: 32px 0px 0px 0px;
      background-color: white;
    }
  }
}

.active > span > svg {
  background-color: white;
  border: 1px solid white;
  color: #2264ae !important;
}
.active {
  margin-left: -5px;
  padding: 5px 10px 5px 8px;
  background-color: white !important;
  border: 1px solid white;
  border-radius: 2px;
  color: #2264ae !important;
}
/* ---style of Tooltip------------- */
:global(.ant-tooltip-inner) {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 12px !important;
  font-weight: 600;
}

//-------Start Sub Menu------------

:global(.ant-menu-sub) {
  //color: rgba(255, 255, 255, 0.65);
  // background: #2264ae !important;
  color: black !important;
  background: #2264ae !important;
}
:global(.ant-menu-item > span > a) {
  color: white !important;
}
:global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected) {
  background-color: #ffffff !important;
  color: #2264ae !important;
}

:global(.ant-menu-dark .ant-menu-item-selected .anticon) {
  color: #2264ae !important;
}

:global(.ant-menu-dark .ant-menu-item-selected > a) {
  color: #2264ae !important;
}
:global(.ant-menu-dark .ant-menu-item-selected > span > a) {
  color: #2264ae !important;
}
:global(.ant-menu-dark .ant-menu-item-selected > span > a:hover) {
  color: #2264ae !important;
}
//-----------End Sub Menu------------

//------Start Main Menu-------------------
:global(.ant-menu-submenu) {
  margin-top: 10px !important;
}
:global(.ant-menu-submenu-selected) {
  background-color: #ffffff !important;
  color: #2264ae !important;
  font-weight: 600;
  border-radius: 4px !important;
}
:global(.ant-menu-submenu-active) {
  background-color: #ffffff !important;
  color: #2264ae !important;
  font-weight: 600;
  border-radius: 4px !important;
}
:global(.ant-menu-submenu-active) {
  background-color: #ffffff !important;
  color: #2264ae !important;
  font-weight: 600;
  border-radius: 4px !important;
}
:global(.ant-menu-submenu-open) :hover {
  background-color: #ffffff !important;
  color: #2264ae !important;
  font-weight: 600;
  border-radius: 4px !important;
}
:global(.ant-menu.ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title) {
  left: 0;
  padding: 0 calc(50% - 16px / 0);
  text-overflow: clip;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 4px !important;
}
//-------------End Main Menu------------
