/* @import url('http://fonts.cdnfonts.com/css/cerebri-sans'); */
/* .container {
  margin-left: 3% !important;
  margin-top: 2% !important;
}
h4 {
  font-size: 18px;
  color: #2364ad;
  font-family: 'Cerebri Sans';
}
p {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 0px !important;
  font-style: normal;
  font-family: 'Cerebri Sans';
}
.cardStyles {
  width: 98%;
  top: 20px;
}
h5 {
  font-size: 13px;
}
.badgestyles > sup {
  height: 14px !important;
  font-size: 8px !important;
  background-color: #cbdfff;
  color: #1c4e9d;
  font-weight: 500;
  font-family: 'Cerebri Sans';
}
.para_styles {
  font-weight: 400;
  font-size: 10px !important;
  line-height: 8px !important;
  font-style: normal;
  font-family: 'Cerebri Sans';
}
.import_style {
  font-size: 10px !important;
  font-weight: normal !important;
  width: 90px;
  font-family: 'Cerebri Sans';
}
.import_style_secondary {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #1c4e9d;
  color: #f0f0ff;
  font-family: 'Cerebri Sans';
  padding-bottom: 21px !important;
  padding-left: 20px !important;
  padding-right: 21px !important;
  padding-top: 6px !important;
}
.import_style_secondary:hover {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:active {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:focus {
  font-size: 10px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.searchbar > div > span {
  font-size: 10px !important;
}
.table_container {
  margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 11px;
  // font-family: 'Cerebri Sans';
}
.table_container td {
  font-size: 11px;
  font-family: 'Cerebri Sans';
}
.formstyles {
  margin-top: 2% !important;
  width: 98%;
}
.formstyles label {
  font-size: 12px !important;
}
.select_styles > div > div > div > div > div {
  height: 33px !important;
  font-size: 12px;
}
.select_styles > div > div > div > div > div > span {
  line-height: 31px !important;
}
.date_styles > div > div > div > div {
  height: 33px !important;
  width: 100% !important;
}
.date_styles > div > div > div > div > div > input {
  font-size: 12px !important;
}
.input_styles > div > div > div > span {
  height: 33px !important;
  font-size: 12px !important;
  width: 100% !important;
}
.input_styles > div > div > div > span > input {
  font-size: 12px !important;
}
.textarea_styles > div > div > div > span > textarea {
  font-size: 12px !important;
}
.divider_style {
  margin-bottom: 17px;
  margin-top: 2px;
}
 */
/* @import url('https://fonts.cdnfonts.com/css/cerebri-sans') */
.container {
  margin-left: 3% !important;
  margin-top: 2% !important;
}
h4 {
  font-size: 18px;
  color: #2364ad;
}
p {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 0px !important;
  font-style: normal;
}
.cardStyles {
  width: 98%;
  top: 20px;
}
h5 {
  font-size: 13px;
}
.badgestyles > sup {
  height: 14px !important;
  font-size: 8px !important;
  background-color: #cbdfff;
  color: #1c4e9d;
  font-weight: 500;
}
.para_styles {
  font-weight: 400;
  font-size: 10px !important;
  line-height: 8px !important;
  font-style: normal;
}
.import_style {
  font-size: 10px !important;
  font-weight: normal !important;
  width: 90px;
}
.button_margin {
  display: flex;
  justify-content: start;
  // align-items: center;
}
.first_next_button_style {
  background-color: #1c4e9d;
  color: white;
  font-weight: 600 !important;
  height: 36px !important;
  line-height: 18px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: center !important;
}
/* .button_style :hover {
    font-size: 12px !important;
    //font-weight:normal !important;
    background-color: #F0F0FF;
    color: #3c68ad !important;
} */
/* .button_style :active {
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #3c68ad !important;
    color: #F0F0FF;
}
.button_style :focus {
    font-size: 10px !important;
    font-weight:normal !important;
    background-color: #3c68ad !important;
    color: #F0F0FF;
} */
.searchbar > div > span {
  font-size: 10px !important;
}
.table_container {
  margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 11px;
  //
}
.table_container td {
  font-size: 11px;
}
.formstyles {
  //margin-top: 2% !important;
  width: 98%;
}
/* .formstyles label {
  font-size: 12px !important;
} */
.input_styles label {
  font-size: 12px !important;
}
.select_styles label {
  font-size: 12px !important;
}
.date_styles label {
  font-size: 12px !important;
}
.textarea_styles label {
  font-size: 12px !important;
}
.select_styles > div > div > div > div > div {
  height: 33px !important;
  font-size: 12px;
  font-family: 'SFProText Regular';
}
.select_styles > div > div > div > div > div > span {
  line-height: 31px !important;
}
.date_styles > div > div > div > div {
  height: 33px !important;
  width: 100% !important;
}
.date_styles > div > div > div > div > div > input {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
.input_styles > div > div > div > span {
  height: 33px !important;
  font-size: 12px !important;
  width: 100% !important;
  font-family: 'SFProText Regular';
}
.input_styles > div > div > div > span > input {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
.textarea_styles > div > div > div > span > textarea {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
.divider_style {
  margin-bottom: 17px;
  margin-top: 2px;
}
//* --------New style added------------------ */

.page_title {
  color: #2364ad;
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
}
.page_sub_title {
  color: #667085;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
}
.tab_btn_style {
  label {
    // font-size: 13px !important;
    // font-weight: 600 !important;
    height: 31px !important;
    // padding: 0px 22px !important;
    line-height: 27px !important;
  }
}
.tab_broder_style:first-child {
  border-radius: 8px 0px 0px 0px;
  border: 1px solid #d0d5dd;
}
.tab_broder_style:last-child {
  border-radius: 0px 8px 0px 0px;
  border: 1px solid #d0d5dd;
}
.tab_broder_style {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-color: #d0d5dd;
  color: #344054;
  border-radius: 1px solid #d0d5dd !important;
}

.tab_broder_style:hover {
  //background-color: #2364ad;
  color: #756eff;

  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-color: #d0d5dd;
}
/* .tab_broder_style:active {
  background-color: #2364AD !important;
  color: #ffffff;
  
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-color: #d0d5dd;
} */
.tab_broder_style-checked:not(.tab_broder_style-disabled)::before {
  background-color: #2364ad !important;
}

.tab_broder_style {
  cursor: pointer;
  &.selected {
    background-color: #2364ad !important;
    color: #ffffff;

    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-color: #d0d5dd;
  }
  &.selected:hover {
    background-color: #ffffff !important;
    color: #2364ad !important;

    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-color: #2364ad;
    border-radius: 1px solid #2364ad !important;
  }
}

.tab_broder_style-checked:not(.tab_broder_style-disabled):active {
  background-color: #2364ad !important;
  color: #ffffff;

  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-color: #d0d5dd;
}
.grey_holder {
  background: #f5f7f9;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px !important;
}
.padding_styles {
  margin-bottom: 5px !important;
}
.delete_icon {
  display: flex;

  justify-content: center;
  align-items: center;
}
.delete_styles {
  padding: 3px 5px;
  background: #d0d5dd;
  border: 1px solid #a3a3a4;
  border-radius: 8px;
  margin-top: 18px;
  color: #ff6156;
}
.delete_styles:hover {
  padding: 3px 5px;
  background: #afb0b1;
  border: 1px solid #a3a3a4;
  border-radius: 8px;
  margin-top: 18px;
  color: #d40e00;
}
.add_button_style {
  margin-top: 20px;
  background-color: #1cc637 !important;
  color: white;
  font-weight: 600 !important;
  height: 36px !important;
  line-height: 16px;
  text-align: center !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.add_button_style:focus {
  background-color: #1cc637 !important;
  color: white !important;
}
.add_button_style:hover {
  background-color: white !important;
  color: #1cc637 !important;
  border: 1px solid #1cc637;
}
.margin_grey_holder {
  margin-left: 8px !important;
  margin-right: 0px !important;
}
.items_title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #2364ad;
  font-family: 'SFProText Bold';
}
.next_button_style {
  background-color: #1c4e9d !important;
  color: white !important;
  font-weight: 600 !important;
  // font-family: 'Cerebri Sans' !important;
  line-height: 18px;
  padding-left: 35px !important;
  padding-right: 35px !important;
  text-align: center !important;
  height: 36px !important;
}
.form_list_margin {
  margin-bottom: 10px !important;
  div {
    margin-bottom: 10px !important;
  }
}
.divider_bottom_margin {
  margin-bottom: 10px !important;
  padding-bottom: 0px !important;
  margin-top: 15px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.formItem_marginBottom {
  margin-bottom: 8px !important;
}
.custom_file_upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 5px 12px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 12px !important;
  font-weight: 500;
}
.custom_file_upload:hover {
  display: inline-block;
  padding: 5px 12px;
  background-color: #1c4e9d;
  cursor: pointer;
  color: white;
  transition: all 0.4s ease-in-out;
  margin-right: 10px;
  font-size: 12px !important;
  font-weight: 500;
}
.upload_button {
  padding: 4px 8px !important;
  font-size: 12px !important;
  height: 31px !important;
  font-weight: 500;
  margin-left: 10px;
}
.remove_button {
  padding: 4px 8px !important;
  font-size: 12px !important;
  height: 31px !important;
  font-weight: 500;
  background-color: rgb(215, 67, 67);
  color: white;
  margin-left: 10px;
}
/* ---------------use for all formItem------------------------------- */
:global(.ant-form-item-control-input) {
  min-height: 30px !important;
}
/* ---------------------------------------------------------- */
div > div > div > div > .materialInput_styles {
  padding: 4px 11px !important;
}
.searchbar > div > span {
  font-size: 12px !important;
}
.searchbar > div > span {
  height: 33px;
}
.searchbar > div > span > input {
  font-size: 13px;
  margin-left: 12px;
}
.searchbar > div {
  display: inline !important;
  // flex: 0 0 30%;
  // max-width: 100%;
}
/* .searchbar{
  display: flex;
  justify-content: end !important;
} */
.back_button:hover {
  color: #2364ad !important;
  border-color: #2364ad !important;
}
.back_button {
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  height: 34px !important;
  line-height: 18px !important;
  padding: 0px 10px !important;
}
.back_icon {
  font-size: 12px;
}
.uploadedDoc_style {
  margin-right: 5px;
  color: #2364ad !important;
}
.uploadedDoc_style:hover {
  color: #4945ff !important;
}
.uploadedDoc_icon_style {
  font-size: 14px;
}
.uploadedDoc_icon_style:hover {
  color: rgb(215, 67, 67);
}
.input_lineHeight {
  line-height: 12px;
  font-size: 12px;
}
